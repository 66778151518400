'use client'

import {
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@dbbs/tailwind-components'
import { NavArrowDown } from 'iconoir-react'
import Link from 'next/link'
import Typography from '../../atoms/Typography'
import { CaseStudyFilterDropdownMenuProps } from './types'

const CaseStudyFilterDropdownMenu = ({ group }: CaseStudyFilterDropdownMenuProps) => (
  <DropdownMenu>
    <DropdownMenuTrigger className="bg-background-grey w-full px-5 py-3 rounded-sm">
      <div className="flex justify-between">
        {group.selectedCategory ? (
          <Typography variant="body-large">{group.selectedCategory.content.title}</Typography>
        ) : (
          <Typography variant="body-large">{`All ${group.title}`}</Typography>
        )}
        <NavArrowDown />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="bg-background-grey min-w-[var(--radix-popper-anchor-width)] overflow-y-auto max-h-[var(--radix-dropdown-menu-content-available-height)]">
      <DropdownMenuGroup>
        <DropdownMenuItem asChild>
          <Link scroll={false} href={{ ...group.linkGroup }}>
            <Typography
              className={cn({ 'text-primary': !group.selectedCategory })}
              variant="body-large"
            >{`All ${group.title}`}</Typography>
          </Link>
        </DropdownMenuItem>

        {group.categories.map((category) => (
          <DropdownMenuItem asChild key={category.uuid}>
            <Link scroll={false} href={{ ...category.link }}>
              <Typography
                className={cn({ 'text-primary': group.selectedCategory?.slug === category.slug })}
                variant="body-large"
              >
                {category.content.title}
              </Typography>
            </Link>
          </DropdownMenuItem>
        ))}
      </DropdownMenuGroup>
    </DropdownMenuContent>
  </DropdownMenu>
)
export default CaseStudyFilterDropdownMenu
