'use client'

import dynamic from 'next/dynamic'
import { memo, useCallback, useEffect, useState } from 'react'
import { LottieAnimationProps } from './types'

const startAnimationEvents = ['mousemove', 'touchstart', 'keydown']

const LottieAnimation = ({
  animationKeyName,
  fallbackComponent,
  width,
  height,
  autoloadDelay = 5000,
  autoplay,
  loop
}: LottieAnimationProps) => {
  const [loadedAnimationData, setLoadedAnimationData] = useState<unknown | null>(null)

  const Lottie = dynamic(() => import('lottie-react'), { ssr: false, loading: () => <>{fallbackComponent}</> })

  const getAnimationData = useCallback(async (animationKey: string) => {
    switch (animationKey) {
      case 'notFoundAnimation': {
        const { default: notFoundAnimation } = await import(
          '../../../../public/animations/notFoundBannerAnimation.json'
        )
        return notFoundAnimation
      }
      case 'loadingCaseStudyAnimation': {
        const { default: notFoundAnimation } = await import(
          '../../../../public/animations/loadingCaseStudyAnimation.json'
        )
        return notFoundAnimation
      }
      default: {
        return null
      }
    }
  }, [])

  const loadLottie = useCallback(async () => {
    const animationData = await getAnimationData(animationKeyName)

    if (animationData) {
      setLoadedAnimationData(animationData)
    }
  }, [animationKeyName, getAnimationData])

  useEffect(() => {
    setTimeout(loadLottie, autoloadDelay)
    startAnimationEvents.forEach((event) => document.addEventListener(event, loadLottie, { once: true, passive: true }))
    return () => {
      startAnimationEvents.forEach((event) => document.removeEventListener(event, loadLottie))
    }
  }, [autoloadDelay, loadLottie])

  if (!animationKeyName) return null

  return (
    <div
      className="h-full w-full flex"
      style={{ maxWidth: `${width}px`, maxHeight: `${height}px`, aspectRatio: `${width} / ${height}` }}
    >
      {loadedAnimationData ? (
        <Lottie animationData={loadedAnimationData} autoplay={autoplay} loop={loop} />
      ) : (
        <>{fallbackComponent}</>
      )}
    </div>
  )
}

export default memo(LottieAnimation)
