export const getDimensionsFromStoryblokImageUrl = (url: string): { width: number; height: number } | null => {
  try {
    const dimensionsPart = url.split('/')[5]
    const [width, height] = dimensionsPart.split('x').map(Number)

    if (Number.isNaN(width) || Number.isNaN(height)) {
      return null
    }
    return { width, height }
  } catch {
    return null
  }
}
