'use client'

import React, { FC, useCallback } from 'react'
import { usePathname } from 'next/navigation'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, NavigationLinkStoryblok } from '../types'
import NavigationLink from '../../components/atoms/NavigationLink'
import { extractStoryblokLink } from '../../utils/extractStoryblokLink'

const StoryblokNavigationLink: FC<Blok<NavigationLinkStoryblok>> = ({
  blok: { title, label, link, isSubmenuLink, activeLinkHighlightCondition, prefetch, ...blok }
}) => {
  const pathname = usePathname()
  const isActiveLink = useCallback(
    (url: string) => (activeLinkHighlightCondition === 'exact' ? pathname === url : pathname.includes(url)),
    [activeLinkHighlightCondition, pathname]
  )

  return (
    <NavigationLink
      {...storyblokEditable(blok)}
      href={extractStoryblokLink(link)}
      isActive={isActiveLink(link.url)}
      badgeContent={label}
      isSubmenuLink={isSubmenuLink}
      prefetch={prefetch}
    >
      {title}
    </NavigationLink>
  )
}

export default StoryblokNavigationLink
