import Image from 'next/image'
import Link from 'next/link'
import { Button, cn } from '@dbbs/tailwind-components'
import { ArrowUpRight } from 'iconoir-react'
import { useTranslations } from 'next-intl'
import Typography from '../../atoms/Typography'
import { getDimensionsFromStoryblokImageUrl } from '../../../utils/getDimensionsFromStoryblokImageUrl'
import { CaseStudyCardProps } from './type'
import Space from '../../atoms/Space'

const CaseStudyCard = ({
  previewImage,
  title,
  logoClientCompany,
  link,
  caseHighlightsSection,
  className
}: CaseStudyCardProps) => {
  const t = useTranslations()

  if (!logoClientCompany?.filename || !previewImage?.filename) {
    return null
  }
  const { width, height } = getDimensionsFromStoryblokImageUrl(logoClientCompany.filename) || {}

  const linkCase = `/${link}`

  return (
    <div
      className={cn(
        'z-10 h-full flex flex-col justify-between bg-background-grey rounded-sm relative overflow-hidden group',
        className
      )}
    >
      <div className="px-x-sm pt-lg-mobile lg:px-lg lg:pt-lg">
        <div className="flex justify-between items-start flex-col-reverse gap-y-md xl:flex-row gap-x-md">
          <span className="bg-clip-text z-10 text-transparent bg-dark group-hover:bg-gradient-to-b from-[#0D141F] to-[#65B0FF] transition duration-300 ease-in-out">
            <Link href={linkCase}>
              <Typography variant="h4" className="max-w-[540px]">
                {title}
              </Typography>
            </Link>
          </span>
          <Image
            src={`${logoClientCompany.filename}/m/`}
            alt={logoClientCompany.alt ?? 'Logo'}
            height={height}
            width={width}
            className="xl:opacity-45 lg:group-hover:opacity-100 transition duration-300 ease-in-out"
          />
        </div>
        <div className="xl:absolute z-20 left-[64px] bottom-[-64px] transition-all duration-300 tran group-hover:bottom-[64px]">
          <div className="block lg:hidden">
            <Space size="medium" />
          </div>
          <div className="mb-sm xl:mb-lg xl:max-w-52">{caseHighlightsSection}</div>

          <Link href={linkCase} aria-label={t('caseStudyCard.ariaLabel')} className="hidden w-fit xl:block">
            <Button variant="secondary" aria-label={t('caseStudyCard.ariaLabel')}>
              <ArrowUpRight />
            </Button>
          </Link>
        </div>
      </div>
      <div className="flex justify-center xl:justify-end">
        <div className="xl:h-[390px] basis-full xl:basis-2/3 flex justify-center xl:justify-normal">
          <Link href={linkCase} aria-label={t('caseStudyCard.ariaLabel')}>
            <Image
              src={previewImage.filename}
              alt={previewImage.alt ?? 'image of case'}
              height={390}
              width={650}
              className="group-hover:scale-110 block object-center aspect-[1.6/1] object-cover transition duration-300 ease-in-out"
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CaseStudyCard
