'use client'

import { Controller, DefaultValues, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Button, Checkbox, cn, Input, Textarea, useToast } from '@dbbs/tailwind-components'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { Attachment, SettingsProfiles } from 'iconoir-react'
import Typography from '../../atoms/Typography'
import { ContactUsFormInput, ContactUsFormProps } from './types'
import { sendEmail } from '../../../app/api/contact'
import { emailRegex } from '../../../constants/regexPatterns'

const defaultValues: DefaultValues<ContactUsFormInput> = {
  name: '',
  email: '',
  hearAboutUs: '',
  message: '',
  privacyPolicy: false,
  sendNDA: false
}

const ContactUsForm = ({ linkToPrivacyPolicy, className }: ContactUsFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting, isValid, isSubmitted, isSubmitSuccessful }
  } = useForm<ContactUsFormInput>({ defaultValues })
  const t = useTranslations()
  const { toast } = useToast()

  const onSubmit: SubmitHandler<ContactUsFormInput> = async (data) => {
    try {
      await sendEmail({
        payload: {
          fromEmail: data.email,
          fromName: data.name,
          hearAboutUs: data.hearAboutUs,
          message: data.message,
          sendMeNDA: data.sendNDA,
          file: data.file[0]
        }
      })
    } catch (error) {
      console.error('Error sending email:', error)

      if (error instanceof Error) {
        setError('root', {
          type: 'manual',
          message: error?.message
        })

        toast({
          variant: 'destructive',
          title: t('contactUsForm.toastErrorTitle'),
          description: error?.message
        })
      }
    }
  }

  const [fileWatch, privacyPolicyWatch, sendNDAWatch] = useWatch({
    control,
    name: ['file', 'privacyPolicy', 'sendNDA']
  })

  const handelClickPrivacyPolicy = () => setValue('privacyPolicy', !privacyPolicyWatch, { shouldValidate: true })

  return (
    <>
      {!isSubmitSuccessful ? (
        <form className={cn('flex flex-col gap-6 lg:gap-7', className)} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Typography
              renderAs="label"
              htmlFor="nameFiledContactUsForm"
              variant="subtitle"
              className="block ml-5 mb-3"
            >
              {t('contactUsForm.labelName')}
            </Typography>

            <Input
              id="nameFiledContactUsForm"
              variant="square"
              {...register('name', { required: true, min: 3, max: 30 })}
              error={!!errors.name}
              disabled={isSubmitting}
            />
          </div>
          <div>
            <Typography
              renderAs="label"
              htmlFor="emailFiledContactUsForm"
              variant="subtitle"
              className="block ml-5 mb-3"
            >
              {t('contactUsForm.labelEmail')}
            </Typography>

            <Input
              id="emailFiledContactUsForm"
              variant="square"
              {...register('email', { required: true, pattern: emailRegex })}
              error={!!errors.email}
              disabled={isSubmitting}
            />
          </div>
          <div>
            <Typography
              renderAs="label"
              htmlFor="hearAboutUsFieldContactUsFrom"
              variant="subtitle"
              className="block ml-5 mb-3"
            >
              {t('contactUsForm.labelHearAboutUs')}
            </Typography>

            <Input
              id="hearAboutUsFieldContactUsFrom"
              variant="square"
              {...register('hearAboutUs')}
              disabled={isSubmitting}
            />
          </div>
          <div>
            <Typography
              renderAs="label"
              htmlFor="messageFieldContactUsForm"
              variant="subtitle"
              className="block ml-5 mb-3"
            >
              {t('contactUsForm.labelMessage')}
            </Typography>

            <Textarea
              id="messageFieldContactUsForm"
              {...register('message', { required: true })}
              disabled={isSubmitting}
              className="resize-none"
              error={!!errors.message}
            />
          </div>

          <div
            className={cn('flex gap-4 items-center', {
              'pointer-events-none opacity-50': isSubmitting
            })}
          >
            <Attachment className="text-primary" />
            <label
              htmlFor="fileInput"
              className={cn('flex gap-1 max-w-[80%] items-center cursor-pointer hover:text-primary')}
            >
              {!fileWatch?.[0] ? (
                <>
                  <Typography variant="link">{t('contactUsForm.attachFile')}</Typography>
                  <Typography variant="body">{t('contactUsForm.typeFiles')}</Typography>
                </>
              ) : (
                <Typography variant="link">{fileWatch[0].name}</Typography>
              )}
            </label>
            <Input
              id="fileInput"
              type="file"
              variant="square"
              accept=".pdf,.txt,.docx"
              hidden
              {...register('file')}
              disabled={isSubmitting}
            />
          </div>

          <div className="flex gap-4">
            <Controller
              name="privacyPolicy"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...restProps }, fieldState: { error } }) => (
                <Checkbox
                  aria-labelledby="privacyPolicyLabelContactUsForm"
                  disabled={isSubmitting}
                  onCheckedChange={onChange}
                  checked={value}
                  error={!!error}
                  {...restProps}
                />
              )}
            />
            <div
              className={cn({
                'pointer-events-none opacity-50': isSubmitting
              })}
              onClick={handelClickPrivacyPolicy}
            >
              <Typography className="cursor-pointer" id="privacyPolicyLabelContactUsForm">
                {t.rich('contactUsForm.privacyPolicyMessage', {
                  privacyPolicy: (chunks) => (
                    <Link
                      href={linkToPrivacyPolicy}
                      onClick={(e) => e.stopPropagation()}
                      className="text-primary hover:underline"
                      target="_blank"
                    >
                      {chunks}
                    </Link>
                  )
                })}
              </Typography>
            </div>
          </div>
          <div className="flex gap-4">
            <Controller
              name="sendNDA"
              control={control}
              render={({ field: { onChange, value, ...restProps }, fieldState: { error } }) => (
                <Checkbox
                  aria-labelledby="sendNDALabelContactUsForm"
                  disabled={isSubmitting}
                  onCheckedChange={onChange}
                  checked={value}
                  error={!!error}
                  {...restProps}
                />
              )}
            />

            <div
              onClick={() => setValue('sendNDA', !sendNDAWatch)}
              className={cn({
                'pointer-events-none opacity-50': isSubmitting
              })}
            >
              <Typography className="cursor-pointer" id="sendNDALabelContactUsForm">
                {t('contactUsForm.sendNDA')}
              </Typography>
            </div>
          </div>
          <Button type="submit" disabled={!isValid && isSubmitted} loading={isSubmitting}>
            {t('common.getInTouch')}
          </Button>
        </form>
      ) : (
        <div className="lg:min-h-[704px] flex flex-col gap-4 justify-end bg-background-grey">
          <SettingsProfiles className="text-primary" height={32} width={32} />
          <Typography variant="h3" weight="semibold" className="uppercase">
            {t('contactUsForm.thankYouTitle')}
          </Typography>
          <Typography variant="body-large" weight="medium">
            {t('contactUsForm.thankYouMessage')}
          </Typography>
        </div>
      )}
    </>
  )
}

export default ContactUsForm
