import * as React from 'react'
import Image from 'next/image'
import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '@dbbs/tailwind-components'
import Link from 'next/link'
import { getDimensionsFromStoryblokImageUrl } from '../../../utils/getDimensionsFromStoryblokImageUrl'
import { AssetStoryblok, MultilinkStoryblok } from '../../../storyblok/types'

export interface LogoClickProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof logoClickVariants> {
  logo: AssetStoryblok
  disabled?: boolean
  isActive?: boolean
  link?: Exclude<MultilinkStoryblok, { linktype?: 'email' } | { linktype?: 'asset' }>
  borderColor?: string | number
  priority?: boolean
}

export const logoClickVariants = cva('flex border min-h-20 w-min-40 p-4 items-center justify-center rounded', {
  variants: {
    variant: {
      darkBG: 'border-white-50',
      whiteBG: 'border-secondary-grey-50'
    },
    withHover: {
      true: 'cursor-pointer active:border-primary',
      false: ''
    }
  },
  compoundVariants: [
    {
      variant: 'darkBG',
      withHover: true,
      class: 'hover:border-white'
    },
    {
      variant: 'whiteBG',
      withHover: true,
      class: 'hover:border-dark'
    }
  ]
})

const LogoClick = ({
  logo,
  disabled,
  isActive,
  className,
  variant,
  link,
  borderColor,
  priority = true,
  ...props
}: LogoClickProps) => {
  if (!logo?.filename) return null
  const hasValidUrl = !!link?.url.length

  const logoDimensions = getDimensionsFromStoryblokImageUrl(logo.filename)

  const LogoClickInstance = (
    <div
      {...props}
      className={cn(
        logoClickVariants({ variant, withHover: hasValidUrl, className }),
        {
          'border-secondary-grey pointer-events-none': disabled,
          'border-primary': isActive
        },
        borderColor && `border-${borderColor}`
      )}
    >
      <Image
        src={logo.filename}
        priority={priority}
        unoptimized
        alt={logo.alt ?? 'Logo'}
        width={logoDimensions?.width}
        height={logoDimensions?.height}
      />
    </div>
  )

  return hasValidUrl ? (
    <Link href={link.url} type={link.target}>
      {LogoClickInstance}
    </Link>
  ) : (
    LogoClickInstance
  )
}

export default LogoClick
