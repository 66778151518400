import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../utils'

const inputVariants = cva(
  'flex w-full outline-none border shadow border-[rgba(0,0,0,0.2)] bg-background transition-all text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-200 [&:disabled+label]:bg-transparent focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        rounded: 'rounded-full px-4 py-3',
        square: 'rounded-sm px-5 py-3'
      }
    },
    defaultVariants: {
      variant: 'rounded'
    }
  }
)

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  error?: boolean
}
// [&:focus-visible+label]:top-0
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, placeholder, variant, error, hidden, ...props }, ref) => (
    <div className={cn('relative w-full', { hidden })}>
      <input
        id={props.id}
        type={type}
        className={cn(inputVariants({ variant, className }), { 'border-alert text-alert': error })}
        ref={ref}
        placeholder={placeholder}
        hidden={hidden}
        {...props}
      />
    </div>
  )
)
Input.displayName = 'Input'

export { Input }
