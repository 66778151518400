'use client'

import { Controller, DefaultValues, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Button, Checkbox, cn, Input, useToast } from '@dbbs/tailwind-components'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'
import Typography from '../../atoms/Typography'
import { RequestChecklistFormInput, RequestChecklistFormProps } from './types'
import { emailRegex } from '../../../constants/regexPatterns'
import { sendEmail } from '../../../app/api/contact'

const defaultValues: DefaultValues<RequestChecklistFormInput> = {
  name: '',
  email: '',
  privacyPolicy: false
}

const RequestChecklistForm = ({ linkToPrivacyPolicy, linkToThankYouPage }: RequestChecklistFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting, isValid, isSubmitted }
  } = useForm<RequestChecklistFormInput>({ defaultValues })
  const t = useTranslations('requestChecklistForm')
  const router = useRouter()
  const { toast } = useToast()

  const onSubmit: SubmitHandler<RequestChecklistFormInput> = async (data) => {
    await sendEmail({
      mailChimpTags: ['sdd_checklist_contact_form'],
      payload: {
        fromEmail: data.email,
        fromName: data.name,
        message: 'Please send an NDA',
        subject: `NDA Request (${data.name}) [sdd-checklist-download]`
      }
    })
      .then(() => linkToThankYouPage && router.push(linkToThankYouPage))
      .catch((error) => {
        console.error('Error sending email:', error)

        if (error instanceof Error) {
          setError('root', {
            type: 'manual',
            message: error?.message
          })

          toast({
            variant: 'destructive',
            title: t('contactUsForm.toastErrorTitle'),
            description: error?.message
          })
        }
      })
  }

  const [privacyPolicyWatch] = useWatch({
    control,
    name: ['privacyPolicy']
  })

  const handlePrivacyPolicyChange = () => setValue('privacyPolicy', !privacyPolicyWatch, { shouldValidate: true })

  return (
    <form className="flex flex-col gap-6 lg:gap-7" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-3">
        <Typography htmlFor="checklist-name-field" renderAs="label" variant="subtitle" className="ml-5">
          {t('labelName')}
        </Typography>
        <Input
          id="checklist-name-field"
          variant="square"
          {...register('name', { required: true, min: 3, max: 30 })}
          error={!!errors.name}
          disabled={isSubmitting}
        />
      </div>
      <div className="flex flex-col gap-3">
        <Typography htmlFor="checklist-email-field" renderAs="label" variant="subtitle" className="ml-5">
          {t('labelEmail')}
        </Typography>
        <Input
          id="checklist-email-field"
          variant="square"
          {...register('email', { required: true, pattern: emailRegex })}
          error={!!errors.email}
          disabled={isSubmitting}
        />
      </div>

      <div className="flex gap-4">
        <Controller
          name="privacyPolicy"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ...restProps }, fieldState: { error } }) => (
            <Checkbox
              aria-label="privacy-policy-checkbox"
              disabled={isSubmitting}
              onCheckedChange={onChange}
              checked={value}
              error={!!error}
              {...restProps}
            />
          )}
        />
        <div
          className={cn({
            'pointer-events-none opacity-50': isSubmitting
          })}
          onClick={handlePrivacyPolicyChange}
        >
          <Typography className="cursor-pointer">
            {t.rich('privacyPolicyMessage', {
              privacyPolicy: (chunks) => (
                <Link
                  href={linkToPrivacyPolicy}
                  onClick={(e) => e.stopPropagation()}
                  className="text-primary hover:underline"
                  target="_blank"
                >
                  {chunks}
                </Link>
              )
            })}
          </Typography>
        </div>
      </div>
      <Button type="submit" disabled={!isValid && isSubmitted} loading={isSubmitting}>
        {t('buttonTitle')}
      </Button>
    </form>
  )
}

export default RequestChecklistForm
