import { buttonVariants, cn } from '@dbbs/tailwind-components'
import { ConsentSettingsTriggerProps } from './types'

const ConsentSettingsTrigger = ({ label }: ConsentSettingsTriggerProps) => (
  <div
    className={cn(
      buttonVariants({
        variant: 'link',
        size: 'link'
      }),
      'cursor-pointer cky-banner-element'
    )}
  >
    {label}
  </div>
)

export default ConsentSettingsTrigger
