'use client'

import React, { FC } from 'react'
import { Blok, TimeReductionChartStoryblok } from '../types'
import TimeReductionChart from '../../components/molecules/TimeReductionChart'

const StoryblokTimeReductionChart: FC<Blok<TimeReductionChartStoryblok>> = ({ blok: { description, percentage } }) => {
  const timeReductionPercentage = parseInt(percentage, 10) || 0

  return <TimeReductionChart percentage={timeReductionPercentage} description={description} />
}

export default StoryblokTimeReductionChart
