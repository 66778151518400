'use client'

import { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { cn } from '@dbbs/tailwind-components'
import Image from 'next/image'
import { CaseStudiesResponse } from '../../organisms/CaseStudyFiltersAndList/types'
import CaseStudyListEmptyState from './CaseStudyListEmptyState'
import CaseStudyCard from '../CaseStudyCard'
import StoryblokBlockMapper from '../../../storyblok/StoryblokBlockMapper'
import { fetchCaseStudies } from '../../organisms/CaseStudyFiltersAndList/actions'
import LottieAnimation from '../../atoms/LottieAnimation'

const CaseStudyList = ({
  initCaseStudiesData,
  industryId,
  serviceId
}: {
  initCaseStudiesData: CaseStudiesResponse
  industryId: string | undefined
  serviceId: string | undefined
}) => {
  const [caseStudies, setCaseStudies] = useState<CaseStudiesResponse>(initCaseStudiesData)
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [ref, inView] = useInView()

  const loadMoreCaseStudies = useCallback(async () => {
    setIsLoading(true)

    try {
      const nextNumberPage = pageNumber + 1
      const { data: caseStudiesResponse } = (await fetchCaseStudies({
        industryId,
        serviceId,
        pageNum: nextNumberPage
      })) as { data: CaseStudiesResponse }

      if (caseStudiesResponse?.stories.length) {
        setPageNumber(nextNumberPage)
        setCaseStudies((prev) => ({
          ...prev,
          stories: [...(prev.stories.length ? prev.stories : []), ...caseStudiesResponse.stories]
        }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [pageNumber, industryId, serviceId])

  useEffect(() => {
    setPageNumber(1)
  }, [industryId, serviceId])

  useEffect(() => {
    if (inView) {
      loadMoreCaseStudies()
    }
  }, [inView, loadMoreCaseStudies])

  useEffect(() => {
    setCaseStudies(initCaseStudiesData)
  }, [initCaseStudiesData])

  return (
    <div
      className={cn('flex flex-col gap-y-md-deluxe h-full', {
        'items-center justify-center': !caseStudies.stories.length
      })}
    >
      {!caseStudies.stories.length && <CaseStudyListEmptyState />}
      {caseStudies.stories.map((caseItem, index) => (
        <div key={caseItem.uuid} ref={index === caseStudies.stories.length - 3 ? ref : null}>
          <CaseStudyCard
            title={caseItem.content.card[0].title}
            link={caseItem.full_slug}
            logoClientCompany={caseItem.content.card[0].logoCompany}
            previewImage={caseItem.content.card[0].previewImage}
            caseHighlightsSection={<StoryblokBlockMapper blocks={caseItem.content.card[0].caseHighlightsSection} />}
          />
        </div>
      ))}
      {isLoading && (
        <div className="flex justify-center">
          <LottieAnimation
            autoplay={true}
            width={128}
            height={128}
            loop={true}
            animationKeyName="loadingCaseStudyAnimation"
            fallbackComponent={
              <Image
                src="/img/fallbackImgCaseStudyLoading.png"
                alt="fallback image for load animation"
                height={128}
                width={128}
              />
            }
          />
        </div>
      )}
    </div>
  )
}

export default CaseStudyList
