'use client'

import { storyblokEditable } from '@storyblok/react'
import { FC } from 'react'
import { Blok, VideoAccordionSectionStoryblok } from '../types'
import VideoAccordion from '../../components/organisms/VideoAccordion'

const StoryblokVideoAccordion: FC<Blok<VideoAccordionSectionStoryblok>> = ({ blok: { title, content, ...blok } }) => (
  <VideoAccordion title={title} content={content} {...storyblokEditable(blok)} />
)

export default StoryblokVideoAccordion
