'use client'

import { FC } from 'react'
import Image from 'next/image'
import { Blok, LottieAnimationStoryblok } from '../types'
import LottieAnimation from '../../components/atoms/LottieAnimation'

const StoryblokLottieAnimation: FC<Blok<LottieAnimationStoryblok>> = ({
  blok: { animationData, fallbackComponent, width, height, fallbackImage, autoloadDelay, ...props }
}) => {
  const fallBackImageSrc = fallbackImage?.filename
  const parsedWidth = Number(width) || 100
  const parsedHeight = Number(height) || 100
  const parsedAutoloadDelay = Number(autoloadDelay) || 5000

  const FallbackComponent = fallBackImageSrc ? (
    <Image src={fallBackImageSrc} width={parsedWidth} height={parsedHeight} alt="Animation preview image" />
  ) : (
    <></>
  )

  return (
    <LottieAnimation
      animationKeyName={animationData}
      width={parsedWidth}
      height={parsedHeight}
      autoloadDelay={parsedAutoloadDelay}
      fallbackComponent={FallbackComponent}
      {...props}
    />
  )
}

export default StoryblokLottieAnimation
