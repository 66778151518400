import Image from 'next/image'
import { cn } from '@dbbs/tailwind-components'
import { HistoryCardProps } from './types'
import Space from '../../atoms/Space'
import Typography from '../../atoms/Typography'
import { DEFAULT_IMAGE_ALT } from '../../../constants/common'

const HistoryCard = ({ title, description, image, isFirstImage }: HistoryCardProps) => (
  <div className="flex gap-sm flex-col max-w-[317px]">
    <Image
      src={image.filename ?? ''}
      alt={image.alt ?? DEFAULT_IMAGE_ALT}
      height={222}
      width={222}
      className={cn('aspect-square rounded-sm', { 'order-1': isFirstImage })}
    />
    <div>
      <Typography variant="h5" weight="semibold">
        {title}
      </Typography>
      <Space size="xxSmall" />
      <Typography variant="body-large" weight="medium">
        {description}
      </Typography>
    </div>
  </div>
)

export default HistoryCard
