'use client'

import { FC, useMemo } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { usePathname } from 'next/navigation'
import { Blok, NavigationFlyoutStoryblok } from '../types'
import NavigationFlyout from '../../components/molecules/NavigationFlyout'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokNavigationFlyout: FC<Blok<NavigationFlyoutStoryblok>> = ({
  blok: { label, title, content, ...blok }
}) => {
  const pathname = usePathname()
  const isActive = useMemo(
    () =>
      content.some((item) =>
        item.links.some(({ link, activeLinkHighlightCondition }) =>
          activeLinkHighlightCondition === 'exact' ? pathname === link.url : pathname.includes(link.url)
        )
      ),
    [content, pathname]
  )

  return (
    <NavigationFlyout {...storyblokEditable(blok)} title={title} badgeContent={label} isActive={isActive}>
      <StoryblokBlockMapper blocks={content} />
    </NavigationFlyout>
  )
}

export default StoryblokNavigationFlyout
