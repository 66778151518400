'use client'

import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, LogoClickStoryblok } from '../types'
import LogoClick from '../../components/molecules/LogoClick'

const StoryblokLogoClick: FC<Blok<LogoClickStoryblok>> = ({
  blok: { image, variant, link, borderColor, priority, ...props }
}) => (
  <LogoClick
    logo={image}
    variant={variant || 'whiteBG'}
    link={link}
    borderColor={borderColor}
    priority={priority}
    {...storyblokEditable(props)}
  />
)

export default StoryblokLogoClick
