'use client'

import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown } from 'lucide-react'

import { mergeRefs } from 'react-merge-refs'
import { cn } from '../utils'

const Accordion = AccordionPrimitive.Root
Accordion.displayName = 'Accordion'
// @ts-expect-error overrides bundled name of the function from package. Required for storybook.
Accordion.name = 'Accordion'

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn('border-b', className)} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    openIcon?: React.ElementType
    closeIcon?: React.ElementType
  }
>(({ className, children, openIcon: OpenIcon = ChevronDown, closeIcon: CloseIcon = ChevronDown, ...props }, ref) => (
  <AccordionPrimitive.Header asChild>
    <div className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'flex text-left flex-1 items-center justify-between py-4 transition-all [&>.close-icon]:hidden [&[data-state=open]>.close-icon]:block [&[data-state=open]>.open-icon]:hidden [&[data-state=open]>svg]:rotate-180 disabled:text-secondary-grey',
          className
        )}
        {...props}
      >
        {children}
        <OpenIcon className="open-icon h-6 w-6 shrink-0 transition-transform duration-200" />
        <CloseIcon className="close-icon h-6 w-6 shrink-0 transition-transform duration-200" />
      </AccordionPrimitive.Trigger>
    </div>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, forwardedRef) => {
  const contentRef = React.useRef<HTMLDivElement>(null)
  const [height, setHeight] = React.useState<string>('0px')
  const [isOpen, setIsOpen] = React.useState(false)

  const localRef = React.useRef<HTMLElement>(null)

  const setRefs = React.useCallback(
    (node: HTMLElement | null) => {
      mergeRefs([localRef, forwardedRef])(node)
    },
    [localRef, forwardedRef]
  )

  React.useEffect(() => {
    const element = localRef.current
    if (!element) return

    const handleStateChange = () => {
      const isOpenState = element.getAttribute('data-state') === 'open'
      setIsOpen(isOpenState)
      if (isOpenState && contentRef.current) {
        setHeight(`${contentRef.current.scrollHeight}px`)
      } else {
        setHeight('0px')
      }
    }

    handleStateChange()

    const observer = new MutationObserver(handleStateChange)
    observer.observe(element, {
      attributes: true,
      attributeFilter: ['data-state']
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <AccordionPrimitive.Content
      ref={setRefs}
      forceMount
      className={cn('overflow-hidden transition-all duration-300')}
      style={{
        maxHeight: isOpen ? height : '0px',
        opacity: isOpen ? 1 : 0,
        visibility: isOpen ? 'visible' : 'hidden'
      }}
      aria-hidden={!isOpen}
      {...props}
    >
      <div ref={contentRef} className={cn('pb-4 pt-0', className)}>
        {children}
      </div>
    </AccordionPrimitive.Content>
  )
})

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
