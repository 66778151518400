'use client'

import { Carousel, CarouselApi, CarouselContent, CarouselItem } from '@dbbs/tailwind-components'
import { useCallback, useState } from 'react'
import CaseStudyPanelCard from '../../molecules/CaseStudyPanelCard'
import { CaseStudyPanelProps } from './types'

const CaseStudyPanel = ({ cards }: CaseStudyPanelProps) => {
  const [emblaApi, setEmblaApi] = useState<CarouselApi | null>(null)

  const handleSetApi = useCallback((api: CarouselApi) => {
    setEmblaApi(api)
  }, [])
  return (
    <div>
      <div className="hidden lg:grid gap-y-md-mobile grid-cols-3 lg:gap-x-md [&>*]:rounded-sm lg:[&>*:first-child]:rounded-bl-lg lg:[&>*:last-child]:rounded-br-lg">
        {cards.map((item) => (
          <CaseStudyPanelCard key={item.linkToCase} {...item} />
        ))}
      </div>
      <div className="block lg:hidden">
        <Carousel fade opts={{ loop: true }} setApi={handleSetApi}>
          <CarouselContent>
            {cards.map((item) => (
              <CarouselItem key={item.linkToCase} className="[&>*]:rounded-b-md [&>*]:rounded-t-sm">
                <CaseStudyPanelCard
                  {...item}
                  onClickNext={emblaApi?.scrollNext}
                  onClickPrevious={emblaApi?.scrollPrev}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  )
}

export default CaseStudyPanel
