import { useEffect } from 'react'
import { animated, useSpring } from '@react-spring/web'
import Image from 'next/image'
import { PerformanceCardProps } from './types'
import Typography from '../../atoms/Typography'

const PerformanceCard = ({ title, subtitle, backgroundImage, percentage }: PerformanceCardProps) => {
  const validPercentage = Math.max(0, Math.min(percentage, 100))

  const [springs, api] = useSpring(() => ({
    animatedPercentage: 0
  }))

  useEffect(() => {
    api.start({
      from: { animatedPercentage: 0 },
      to: { animatedPercentage: validPercentage },
      config: { duration: 1500 }
    })
  }, [validPercentage, api])
  return (
    <div className="relative py-[84px] px-16 lg:px-14 rounded-sm w-full h-full flex items-center justify-center">
      <Image
        src={backgroundImage}
        alt="Perfomance card background image"
        priority
        fill
        sizes="(min-width: 375px) 100vw"
        className="absolute left-0 top-0 h-full w-full rounded-sm -z-10 object-cover"
      />
      <div className="flex flex-col">
        {title}
        <div className="flex items-center gap-4 lg:gap-3">
          <Typography variant="h2" className="text-white">
            <animated.span>{springs.animatedPercentage.to((val) => `${Math.round(val)}%`)}</animated.span>
          </Typography>
          <div className="max-w-32">{subtitle}</div>
        </div>
      </div>
    </div>
  )
}

export default PerformanceCard
