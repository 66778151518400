'use client'

import { FC, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Expand, Minus, Plus } from 'iconoir-react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button, cn } from '@dbbs/tailwind-components'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Space from '../../atoms/Space'
import Typography from '../../atoms/Typography'
import StoryblokBlockMapper from '../../../storyblok/StoryblokBlockMapper'
import { DEFAULT_VIDEO_SRC } from '../../../constants/common'
import { HTMLVideoElementWithEnterFullscreen, VideoAccordionProps } from './types'

const VideoAccordion: FC<VideoAccordionProps> = ({ title, content }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const { ref, inView } = useInView()
  const videoRef = useRef<HTMLVideoElement>(null)
  const t = useTranslations()

  const handleFullScreen = () => {
    const video = videoRef.current
    if (!video) return

    if (video.requestFullscreen) {
      video.requestFullscreen().catch((err) => {
        console.error('Failed to enter fullscreen:', err)
      })
    } else if ((video as HTMLVideoElementWithEnterFullscreen).webkitEnterFullscreen) {
      ;(video as HTMLVideoElementWithEnterFullscreen).webkitEnterFullscreen()
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      if (inView) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [inView, activeIndex])

  useEffect(() => {
    setIsLoaded(false)
  }, [activeIndex])

  const renderContent = () => (
    <div className="flex flex-col gap-4 md:gap-6 ">
      {content.map(({ accordionItem }, index) => (
        <AccordionItem
          value={index.toString()}
          key={`accordion-${accordionItem[0].title[0].text}-${index}`}
          className="border-none"
        >
          <AccordionTrigger
            openIcon={Plus}
            closeIcon={Minus}
            className="[&[data-state=open]>:first-child]:text-primary p-0"
          >
            <StoryblokBlockMapper blocks={accordionItem[0].title} />
          </AccordionTrigger>
          <AccordionContent>
            <StoryblokBlockMapper blocks={accordionItem[0].content} />
          </AccordionContent>
        </AccordionItem>
      ))}
    </div>
  )

  const onPlayHandler = () => {
    if (inView && videoRef.current) {
      videoRef.current.play()
    }
    setIsLoaded(true)
  }

  return (
    <div ref={ref}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-md">
        <div className="order-2 lg:order-1 item bg-gradient-background rounded-md px-x-sm py-lg-mobile lg:pr-none lg:py-lg lg:pl-lg lg:rounded-lg">
          <div className={cn('aspect-[1.3/1] relative', { 'shadow-video': isLoaded })}>
            <video
              playsInline
              muted
              loop
              className="w-full h-full object-cover object-left rounded"
              key={content[activeIndex].video.filename}
              ref={videoRef}
              onPlay={onPlayHandler}
              onCanPlay={onPlayHandler}
            >
              <source src={content[activeIndex].video.filename || DEFAULT_VIDEO_SRC} type="video/mp4" />
            </video>
            {!isLoaded && (
              <Image
                src={`${content[activeIndex].videoThumbnail?.filename}/m/`}
                alt={content[activeIndex].videoThumbnail?.alt || ''}
                fill={true}
                className="rounded"
              />
            )}
          </div>
          <Space size="large" />
          <div className="flex items-center justify-end gap-6 lg:pr-lg">
            <Typography variant="cta" weight="normal" className="text-white">
              {t('common.fullScreen')}
            </Typography>
            <Button
              loading={!isLoaded}
              variant="primaryDarkBG"
              className="p-[13px]"
              onClick={handleFullScreen}
              aria-label={t('common.fullScreen')}
            >
              <Expand />
            </Button>
          </div>
        </div>
        <div className="order-1 lg:order-2 py-lg-mobile px-x-sm lg:py-md-deluxe lg:px-lg">
          <StoryblokBlockMapper blocks={title} />
          <Space size="medium" />
          <Accordion
            type="single"
            defaultValue="0"
            onValueChange={(value) => {
              setActiveIndex(Number(value))
            }}
          >
            {renderContent()}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default VideoAccordion
