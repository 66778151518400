import { cn } from '@dbbs/tailwind-components'
import { BadgeProps } from './types'

const Badge = ({ badgeContent, className }: BadgeProps) => (
  <span
    className={cn(
      'absolute top-0 right-0 inline-flex items-center justify-center whitespace-nowrap p-1 text-[10px] font-semibold leading-[10px] text-dark bg-gradient-to-l from-badge-1 to-badge-2 rounded-sm',
      className
    )}
  >
    {badgeContent}
  </span>
)

export default Badge
