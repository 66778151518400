interface SendEmailPayload {
  fromName: string
  fromEmail: string
  hearAboutUs?: string
  message: string
  subject?: string
  file?: File
  sendMeNDA?: boolean
}

interface SendEmailOptions {
  payload: SendEmailPayload
  mailChimpTags?: string[]
}

const formatHearAboutUs = (hearAboutUs?: string): string | undefined =>
  hearAboutUs ? `How did you hear about us: ${hearAboutUs}\n` : undefined

const formatMessage = (payload: SendEmailPayload): string => {
  let { message } = payload

  message += '\n\n'

  if (payload.hearAboutUs) {
    message += formatHearAboutUs(payload.hearAboutUs)
  }
  if (payload.sendMeNDA) {
    message += 'The user has requested an NDA. Please review and send it if appropriate.\n'
  }

  return message
}

const sendEmail = async ({ payload, mailChimpTags }: SendEmailOptions): Promise<number> => {
  const { fromEmail, fromName, file, subject } = payload
  const message = formatMessage(payload)
  let apiUrl = '/api/send-email'

  if (mailChimpTags) {
    mailChimpTags.forEach((tag) => {
      apiUrl += `${apiUrl.includes('?') ? '&' : '?'}mailChimpTags[]=${tag}`
    })
  }

  const formData = new FormData()
  formData.append('fromEmail', fromEmail)
  formData.append('fromName', fromName)
  formData.append('message', message)

  if (subject) {
    formData.append('subject', subject)
  }

  if (file) {
    formData.append('file', file)
  }

  const res = await fetch(apiUrl, {
    method: 'POST',
    body: formData
  })

  if (!res.ok) {
    const body = await res.json()

    if (res.status === 422) {
      if (body.error && body.error.length) {
        throw new Error(body.error[0].message)
      }
    } else {
      throw new Error('Please try again later.')
    }
  }

  return res.status
}

export { sendEmail }
