'use client'

import { storyblokEditable } from '@storyblok/react'
import { FC } from 'react'
import SearchInput from '../../components/molecules/SearchInput'
import { Blok, SearchInputStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'

const StoryblokSearchInput: FC<Blok<SearchInputStoryblok>> = ({ blok: { placeholder, icon, button, ...blok } }) => (
  <SearchInput
    placeholder={placeholder || 'Search'}
    button={<StoryblokBlockMapper blocks={button} />}
    icon={<StoryblokBlockMapper blocks={icon} />}
    {...storyblokEditable(blok)}
  />
)

export default StoryblokSearchInput
