'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { cn } from '@dbbs/tailwind-components'
import Image from 'next/image'
import { TechnologySectionProps } from './types'
import Typography from '../../atoms/Typography'

const SLIDE_INTERVAL_MS = 5000

const TechnologiesSection = ({ technologies, categories, title, description }: TechnologySectionProps) => {
  const [activeCategory, setActiveCategory] = useState(categories.at(0)?.uuid)
  const [isHovered, setIsHovered] = useState(false)
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const handleCategoryClick = (uuid: string) => {
    setActiveCategory(uuid)
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const startInterval = useCallback(() => {
    intervalRef.current = setInterval(() => {
      setActiveCategory((prevActiveCategory) => {
        const currentIndex = categories.findIndex((cat) => cat.uuid === prevActiveCategory)
        const nextIndex = (currentIndex + 1) % categories.length
        return categories[nextIndex]?.uuid
      })
    }, SLIDE_INTERVAL_MS)
  }, [categories])

  const stopInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [])

  useEffect(() => {
    if (!isHovered) {
      startInterval()
    } else {
      stopInterval()
    }

    return () => {
      stopInterval()
    }
  }, [isHovered, startInterval, stopInterval])

  return (
    <div
      className="px-x-sm lg:px-lg flex flex-col gap-md md:gap-md-deluxe"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-md">
        <div className="basis-1/2">
          <div className="max-w-96">{title}</div>
        </div>
        <div className="basis-1/2">{description}</div>
      </div>
      <div className="flex justify-start md:justify-center flex-wrap gap-2 md:gap-md max-md:overflow-x-auto max-md:mt-4">
        {categories.map(({ uuid, title: categoryTitle }) => (
          <button
            key={uuid}
            onClick={() => handleCategoryClick(uuid)}
            className={cn(
              'whitespace-nowrap rounded border px-4 py-3 text-base font-semibold leading-[14px] hover:border-black',
              {
                'bg-primary text-white': activeCategory === uuid
              }
            )}
          >
            {categoryTitle}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-sm lg:gap-md">
        {technologies.map(({ uuid, technologyImage, technologyName, category }) => {
          const isActiveCategory = activeCategory === category.uuid

          return (
            <div
              key={uuid}
              className={cn('transition-all duration-500 flex flex-col gap-2 items-center justify-between grayscale', {
                'grayscale-0': isActiveCategory
              })}
            >
              <Image
                src={technologyImage.filename as string}
                alt={technologyImage.alt as string}
                width={56}
                height={56}
                className="h-14 w-14 object-contain"
              />
              <Typography
                variant="link"
                className={cn(
                  'transition-all duration-500 self-bottom text-center opacity-0 max-md:hidden w-full overflow-hidden text-ellipsis',
                  {
                    'opacity-100': isActiveCategory
                  }
                )}
              >
                {technologyName}
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TechnologiesSection
