import React, { useRef, useEffect, useState } from 'react'
import { useSpring, animated } from '@react-spring/web'

interface TimeReductionChartIconProps {
  curveLineData: string
}

const TimeReductionChartIcon = ({ curveLineData }: TimeReductionChartIconProps) => {
  const pathRef = useRef<SVGPathElement>(null)
  const [pathLength, setPathLength] = useState(0)

  useEffect(() => {
    if (pathRef.current) {
      const length = pathRef.current.getTotalLength()
      setPathLength(length)
    }
  }, [curveLineData])

  const [springs, api] = useSpring(() => ({
    strokeDashoffset: pathLength
  }))

  useEffect(() => {
    api.start({
      from: { strokeDashoffset: pathLength },
      to: { strokeDashoffset: 0 },
      reset: true,
      config: { duration: 1500 }
    })
  }, [curveLineData, pathLength, api])

  return (
    <svg className="w-full" viewBox="0 0 105 107" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="73" y1="10" x2="3" y2="10" className="stroke-line-grey" />
      <line x1="73" y1="50" x2="3" y2="50" className="stroke-line-grey" />
      <line x1="73" y1="90" x2="3" y2="90" className="stroke-line-grey" />
      <text x="78" y="14" className="fill-secondary-grey text-xs">
        100
      </text>
      <text x="78" y="54" className="fill-secondary-grey text-xs">
        50
      </text>
      <text x="78" y="94" className="fill-secondary-grey text-xs">
        0
      </text>
      <animated.path
        ref={pathRef}
        fillRule="evenodd"
        clipRule="evenodd"
        d={curveLineData}
        strokeWidth={5}
        strokeLinecap="round"
        className="stroke-gradient-1"
        strokeDasharray={pathLength}
        strokeDashoffset={springs.strokeDashoffset}
      />
    </svg>
  )
}

export default TimeReductionChartIcon
