'use client'

import { useState } from 'react'
import { cn } from '@dbbs/tailwind-components'
import { ArrowRight } from 'iconoir-react'
import Typography from '../../atoms/Typography'
import ServicesDetailsListProps from './types'

const ServicesDetailsList = ({ title, content }: ServicesDetailsListProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className="flex flex-col lg:flex-row gap-md lg:gap-xx-lg">
      <div className="flex flex-col basis-7/12 justify-center">
        <div className="flex flex-col gap-md-mobile lg:gap-md lg:py-lg lg:pl-lg h-fit">
          <Typography variant="h2" weight="semibold" className="uppercase">
            {title}
          </Typography>
          <div className="flex flex-col gap-sm-mobile lg:gap-sm">
            {content.map((item, index) => (
              <button
                key={item._uid}
                onClick={() => setActiveIndex(index)}
                className={cn('flex justify-between items-center cursor-pointer w-full text-left', {
                  'text-primary': activeIndex === index
                })}
                aria-pressed={activeIndex === index}
              >
                <Typography variant="h5" weight="semibold">
                  {item.title}
                </Typography>
                <ArrowRight className="w-4 h-4" />
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="basis-5/12 bg-gradient-background rounded-md px-x-sm py-lg-mobile lg:p-lg lg:rounded-lg">
        {content[activeIndex].content}
      </div>
    </div>
  )
}

export default ServicesDetailsList
