'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Carousel, CarouselApi, CarouselContent, CarouselItem, cn } from '@dbbs/tailwind-components'
import HistoryCard from '../../molecules/HistoryCard'
import Space from '../../atoms/Space'
import { CompanyHistoryCarouselProps } from './types'

const CompanyHistoryCarousel = ({ title, historyCards }: CompanyHistoryCarouselProps) => {
  const [emblaApi, setEmblaApi] = useState<CarouselApi | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleSetApi = useCallback((api: CarouselApi) => {
    setEmblaApi(api)
  }, [])

  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) return
    setSelectedIndex(api.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)

    return () => {
      emblaApi.off('reInit', onSelect).off('select', onSelect)
    }
  }, [emblaApi, onSelect])

  const groupedByYear = useMemo(() => {
    const groups = historyCards.reduce(
      (acc, card, index) => {
        const year = dayjs(card.date).year().toString()

        if (!acc[year]) {
          acc[year] = []
        }

        acc[year].push({ ...card, isFirstImage: index % 2 !== 0 })
        return acc
      },
      {} as Record<string, (typeof historyCards)[number][]>
    )

    Object.keys(groups).forEach((year) => {
      groups[year].sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
    })

    return groups
  }, [historyCards])

  const years = useMemo(() => Object.keys(groupedByYear).sort((a, b) => Number(a) - Number(b)), [groupedByYear])

  const handleClickYear = useCallback(
    (index: number) => {
      if (!emblaApi) return

      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  return (
    <div>
      <div className="px-x-sm lg:px-lg">
        <div>{title}</div>
        <Space size="mediumDelux" />
        <div className="flex justify-between sm:justify-center gap-md-mobile lg:gap-md flex-wrap">
          {years.map((item, index) => (
            <Button
              variant="secondary"
              onClick={() => handleClickYear(index)}
              key={`button-year-${item}`}
              className={cn('min-w-[85px] bg-white', { 'bg-primary text-white': index === selectedIndex })}
            >
              {item}
            </Button>
          ))}
        </div>
      </div>
      <Space size="mediumDelux" />

      <div className="bg-background-grey py-lg-mobile lg:py-lg rounded-md lg:rounded-lg">
        <Carousel opts={{ dragFree: true, align: 'start' }} setApi={handleSetApi}>
          <CarouselContent className="first:ml-sm-mobile [&>*:not(:first-child):not(:last-child)]:pl-md-mobile last:mr-sm-mobile lg:first:ml-lg lg:last:mr-lg lg:[&>*:not(:first-child):not(:last-child)]:pl-md">
            {years.map((element) => (
              <CarouselItem key={`group-history-card-${element}`} className="flex gap-x-lg basis-auto">
                {groupedByYear[element].map((item) => (
                  <HistoryCard
                    key={item._uid}
                    title={dayjs(item.date).format('MMMM YYYY')}
                    image={item.image}
                    isFirstImage={item.isFirstImage}
                    description={item.description}
                  />
                ))}
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  )
}

export default CompanyHistoryCarousel
