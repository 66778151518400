import { cn, NavigationMenuItem, NavigationMenuLink } from '@dbbs/tailwind-components'
import Link from 'next/link'
import { ArrowRight } from 'iconoir-react'
import Badge from '../Badge'
import { NavLinkProps } from './types'

const NavigationLink = ({
  href,
  badgeContent,
  isActive = false,
  children,
  isSubmenuLink = false,
  prefetch = true
}: NavLinkProps) => {
  const LinkInstance = (
    <NavigationMenuLink asChild active={isActive} className="max-lg:list-none">
      <Link
        href={href}
        prefetch={prefetch}
        className={cn('relative flex max-xl:justify-between max-xl:items-center', {
          'xl:min-h-[44px] xl:items-end xl:pb-[22px]': !isSubmenuLink,
          'xl:pt-[22px]': badgeContent
        })}
      >
        <div className="max-xl:flex max-xl:items-center max-xl:gap-2">
          {children}
          {badgeContent && <Badge badgeContent={badgeContent} className="max-xl:static" />}
        </div>
        {!isSubmenuLink && <ArrowRight className="lg:hidden text-primary" />}
      </Link>
    </NavigationMenuLink>
  )

  return isSubmenuLink ? LinkInstance : <NavigationMenuItem>{LinkInstance}</NavigationMenuItem>
}

export default NavigationLink
