'use client'

import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Image from 'next/image'
import { InViewImageProps } from './types'

const InViewImage = ({
  contentContainerId,
  priority = false,
  fallbackClassName,
  intersectionOptions,
  ...props
}: InViewImageProps) => {
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null)
  const [shouldLoadImage, setShouldLoadImage] = useState(priority)

  useEffect(() => {
    if (!contentContainerId) {
      return
    }

    setRootElement(document.getElementById(contentContainerId) as HTMLElement | null)
  }, [contentContainerId])

  const { ref, inView } = useInView({
    root: rootElement,
    triggerOnce: true,
    ...intersectionOptions
  })

  useEffect(() => {
    if (inView) {
      setShouldLoadImage(true)
    }
  }, [inView])

  return (
    <div ref={ref}>
      {shouldLoadImage ? (
        <Image
          {...props}
          priority={priority}
          {...(!priority && {
            blurDataURL:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8+eDZfwAI2QOgjsrYSQAAAABJRU5ErkJggg==',
            placeholder: 'blur'
          })}
        />
      ) : (
        <div className={fallbackClassName} />
      )}
    </div>
  )
}

export default InViewImage
