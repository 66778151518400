import { ArrowDownRight, Star } from 'iconoir-react'
import { cn } from '@dbbs/tailwind-components'
import Typography from '../../atoms/Typography'
import { TileProps } from './types'

const Tile = ({ title, showIcon, icon, disabled, active, className, ...props }: TileProps) => (
  <div
    className={cn(
      'p-4 border hover:cursor-pointer border-secondary-grey-50 gap-y-6 rounded-sm flex focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 hover:border-primary *:active:text-primary dark:hover:border-white cursor-default flex-col *:text-dark justify-between dark:border-white-50 dark:*:text-white',
      className,
      { '*:text-primary border-primary': active },
      { 'border-secondary-grey *:text-secondary-grey pointer-events-none': disabled }
    )}
    tabIndex={disabled ? -1 : 0}
    {...props}
  >
    <Typography variant="link" className="overflow-hidden text-ellipsis text-wrap">
      {title}
    </Typography>
    <div className={cn('flex justify-end', { 'justify-between': showIcon })}>
      {showIcon && (icon || <Star />)}
      <ArrowDownRight />
    </div>
  </div>
)

export default Tile
