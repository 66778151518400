'use client'

import {
  Avatar,
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from '@dbbs/tailwind-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { ArrowLeft, ArrowRight } from 'iconoir-react'
import Image from 'next/image'
import Typography from '../../atoms/Typography'
import { ReviewsSectionProps } from './types'
import LogoClick from '../../molecules/LogoClick'
import Tile from '../../molecules/Tile'

const ReviewsSection = ({ reviews, title, seeAllUrl, variant = 'primary' }: ReviewsSectionProps) => {
  const t = useTranslations()
  const [emblaApi, setEmblaApi] = useState<CarouselApi | null>(null)
  const [scrollSnapsEmblaApi, setScrollSnapsEmblaApi] = useState<CarouselApi | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const isSecondaryVariant = variant === 'secondary'

  const rootNodeRef = useRef<HTMLDivElement | null>(null)

  const getRootNode = useCallback(() => rootNodeRef.current, [])

  const handleSetApi = useCallback((api: CarouselApi) => {
    setEmblaApi(api)
  }, [])

  const handleSetScrollSnapsApi = useCallback((api: CarouselApi) => {
    setScrollSnapsEmblaApi(api)
  }, [])

  const onInit = useCallback((api: CarouselApi) => {
    if (!api) return
    setScrollSnaps(api.scrollSnapList())
  }, [])

  const onSelect = useCallback((api: CarouselApi) => {
    if (!api) return
    setSelectedIndex(api.selectedScrollSnap())
  }, [])

  const handlePause = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    if (autoplay.isPlaying()) {
      autoplay.stop()
    }
  }, [emblaApi])

  const handlePlay = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    if (!autoplay.isPlaying()) {
      autoplay.play()
    }
  }, [emblaApi])

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return
      handlePause()
      emblaApi.scrollTo(index)
    },
    [emblaApi, handlePause]
  )

  const handleTouchStart = useCallback(
    (event: TouchEvent) => {
      if (rootNodeRef.current && !rootNodeRef.current.contains(event.target as Node)) {
        handlePlay()
      }
    },
    [handlePlay]
  )

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart)
    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
    }
  }, [handleTouchStart])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)

    return () => {
      emblaApi.off('select', onSelect).off('reInit', onInit).off('reInit', onSelect)
    }
  }, [emblaApi, onInit, onSelect])

  useEffect(() => {
    if (!scrollSnapsEmblaApi) return

    scrollSnapsEmblaApi.scrollTo(selectedIndex)
  }, [scrollSnapsEmblaApi, selectedIndex])

  useEffect(() => {
    if (!scrollSnapsEmblaApi) return

    scrollSnapsEmblaApi.on('pointerDown', handlePause)

    return () => {
      scrollSnapsEmblaApi.off('pointerDown', handlePause)
    }
  }, [handlePause, scrollSnapsEmblaApi])

  return (
    <div ref={rootNodeRef} onMouseLeave={handlePlay} className="select-none">
      <Carousel
        setApi={handleSetApi}
        opts={{ loop: true }}
        autoplay
        fade={isSecondaryVariant}
        autoplayOptions={{
          playOnInit: true,
          delay: 4000,
          stopOnInteraction: true,
          stopOnFocusIn: true,
          stopOnMouseEnter: true,
          rootNode: getRootNode
        }}
      >
        <div className="max-md:px-4 flex justify-between flex-col xl:flex-row gap-8">
          <div className="xl:max-w-96 shrink-0">{title}</div>
          <CarouselContent className="flex shrink-0">
            {reviews.map((review, index) => (
              <CarouselItem key={`review-${review.name}-${index}`} className="flex flex-col md:flex-row gap-8">
                <div className="flex flex-col items-start gap-md-mobile basis-4/5 md:basis-4/6">
                  <Typography variant="h4">{review.review}</Typography>
                  {isSecondaryVariant && <div className="hidden md:block"> {review.buttonToSource}</div>}
                </div>
                <div className="relative flex-col justify-between basis-1/5 md:basis-4/12">
                  <div className="flex gap-5 shrink-0">
                    <Avatar className="w-12 h-12">
                      <Image src={review.avatar.src} width={48} height={48} alt={review.avatar.alt} />
                    </Avatar>
                    <div className="flex flex-col gap-1 xl:max-w-48">
                      <Typography variant="subtitle">{review.name}</Typography>
                      <Typography variant="body">{review.position}</Typography>
                    </div>
                  </div>
                  {isSecondaryVariant && (
                    <div className="mt-md">
                      <div className="flex justify-between gap-xxx-sm md:justify-normal md:gap-x-md">
                        <CarouselPrevious
                          variant="secondary"
                          size="default"
                          className="static -right-0 -top-0 -translate-y-0"
                          iconPrevious={ArrowLeft}
                        />
                        <div className="block md:hidden">{review.buttonToSource}</div>
                        <CarouselNext
                          variant="secondary"
                          size="default"
                          className="static -left-0 -top-0 -translate-y-0"
                          iconNext={ArrowRight}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </div>

        {!isSecondaryVariant && (
          <>
            <div className="hidden md:flex gap-2 lg:gap-md mt-12 flex-wrap w-full justify-between">
              {scrollSnaps.map((_, index) => (
                <LogoClick
                  key={index}
                  logo={reviews[index]?.companyLogo ?? ''}
                  onClick={() => onDotButtonClick(index)}
                  className="hover:cursor-pointer hover:border-dark w-[175px] h-20"
                  isActive={index === selectedIndex}
                  priority={false}
                />
              ))}
              <Link href={seeAllUrl}>
                <Tile title={t('seeAll')} className="min-w-[175px] hover:cursor-pointer gap-y-1" />
              </Link>
            </div>
            <Carousel className="pl-4 mt-8 md:hidden" opts={{ dragFree: true }} setApi={handleSetScrollSnapsApi}>
              <CarouselContent>
                {scrollSnaps.map((_, index) => (
                  <CarouselItem key={index} className="max-w-40 pl-2 first:pl-4">
                    <LogoClick
                      logo={reviews[index]?.companyLogo ?? ''}
                      onClick={() => onDotButtonClick(index)}
                      className="hover:cursor-pointer hover:border-dark h-full"
                      isActive={index === selectedIndex}
                      priority={false}
                    />
                  </CarouselItem>
                ))}
                <CarouselItem className="max-w-40 pl-2">
                  <Link href={seeAllUrl}>
                    <Tile title={t('seeAll')} className="min-w-[144px] hover:cursor-pointer gap-y-1" />
                  </Link>
                </CarouselItem>
              </CarouselContent>
            </Carousel>
          </>
        )}
      </Carousel>
    </div>
  )
}

export default ReviewsSection
