'use client'

import { FC, MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { cn, NavigationMenu, NavigationMenuList } from '@dbbs/tailwind-components'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

interface HeaderProps {
  logo: string
  links: ReactNode
  cta: ReactNode
}

const Header: FC<HeaderProps> = ({ logo, cta, links, ...props }) => {
  const pathname = usePathname()
  const isHomePage = pathname === '/'

  const [isScrolled, setIsScrolled] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const handleToggleMobileMenu = () => setIsMobileMenuOpen((prev) => !prev)
  const handleCloseMobileMenu = () => setIsMobileMenuOpen(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 1)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isMobileMenuOpen])

  const handleMobileMenuClick = useCallback((e: MouseEvent<HTMLUListElement>) => {
    const target = e.target as HTMLElement
    if (target.closest('a')) {
      setIsMobileMenuOpen(false)
    }
  }, [])

  return (
    <header
      className={cn('sticky top-0 z-40 bg-white', {
        'shadow-[0px_14px_24px_-15px_rgba(2,5,37,0.25)]': isScrolled
      })}
      {...props}
    >
      <div className="flex flex-col gap-6 mx-auto max-w-[1362px] relative z-50 bg-white p-8 lg:px-20 py-2 xl:py-1">
        <div className="flex gap-4 items-center justify-between">
          <div>
            {isHomePage ? (
              <Image width={103} height={40} src={logo} unoptimized alt="DBB Software logo" priority />
            ) : (
              <Link href="/" onClick={handleCloseMobileMenu}>
                <Image width={103} height={40} src={logo} unoptimized alt="DBB Software logo" priority />
              </Link>
            )}
          </div>
          <NavigationMenu className="max-xl:hidden">
            <NavigationMenuList className="flex xl:gap-6 2xl:gap-md min-h-[44px] items-end">{links}</NavigationMenuList>
          </NavigationMenu>
          <div className="max-lg:hidden">{cta}</div>

          <button
            onClick={handleToggleMobileMenu}
            className="flex flex-col justify-center items-center min-h-7 lg:hidden"
            aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
          >
            <span
              className={cn('bg-dark block transition-all duration-300 ease-out h-0.5 w-7', {
                'rotate-45 translate-y-0.5': isMobileMenuOpen,
                '-translate-y-2': !isMobileMenuOpen
              })}
            ></span>
            <span
              className={cn('bg-dark block transition-transform duration-300 ease-out h-0.5 w-7', {
                'opacity-0': isMobileMenuOpen
              })}
            ></span>
            <span
              className={cn('bg-dark block transition-all duration-300 ease-out h-0.5 w-7', {
                '-rotate-45 -translate-y-0.5': isMobileMenuOpen,
                'translate-y-2': !isMobileMenuOpen
              })}
            ></span>
          </button>
        </div>

        <hr className="hidden lg:block xl:hidden h-[1px] bg-line-grey" />

        <NavigationMenu className="hidden lg:block xl:hidden max-w-full [&>div:first-child]:w-full">
          <NavigationMenuList className="flex justify-between gap-6 items-center">{links}</NavigationMenuList>
        </NavigationMenu>
      </div>

      <NavigationMenu className="static lg:hidden">
        <ul
          className={cn(
            'absolute transition-all duration-300 overflow-y-auto z-30 top-0 -left-[100%] w-full pt-20 pb-16 h-screen px-8 bg-white flex flex-col gap-4',
            {
              'left-0': isMobileMenuOpen
            }
          )}
          onClick={handleMobileMenuClick}
        >
          {links}
        </ul>
      </NavigationMenu>
    </header>
  )
}

export default Header
