'use client'

import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, PerformanceCardStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import PerformanceCard from '../../components/molecules/PerformanceCard'

const DEFAULT_BACKGROUND_IMAGE_PATH = '/img/heroMiddleTileBackground.png'

const StoryblokPerformanceCard: FC<Blok<PerformanceCardStoryblok>> = ({
  blok: { title, subtitle, percentage, backgroundImage, ...props }
}) => {
  const performanceCardPercentage = parseInt(percentage, 10) || 30

  return (
    <PerformanceCard
      title={<StoryblokBlockMapper blocks={title} />}
      subtitle={<StoryblokBlockMapper blocks={subtitle} />}
      percentage={performanceCardPercentage}
      backgroundImage={backgroundImage?.filename ? backgroundImage?.filename : DEFAULT_BACKGROUND_IMAGE_PATH}
      {...storyblokEditable(props)}
    />
  )
}

export default StoryblokPerformanceCard
