import React, { useEffect, useMemo } from 'react'
import { animated, useSpring } from '@react-spring/web'
import Typography from '../../atoms/Typography'
import { TimeReductionChartProps } from './types'
import TimeReductionChartIcon from './TimeReductionChartIcon'

const TimeReductionChart = ({ percentage, description }: TimeReductionChartProps) => {
  const validPercentage = Math.max(0, Math.min(percentage, 100))

  const [springs, api] = useSpring(() => ({
    animatedPercentage: 0
  }))

  useEffect(() => {
    api.start({
      from: { animatedPercentage: 0 },
      to: { animatedPercentage: validPercentage },
      config: { duration: 1500 }
    })
  }, [validPercentage, api])

  const CONTROL_POINT_1_RATIO_X = 0.8
  const CONTROL_POINT_1_RATIO_Y = 0.3
  const CONTROL_POINT_2_RATIO_X = 0.3
  const CONTROL_POINT_2_RATIO_Y = 0.8

  const pathData = useMemo(() => {
    const startX = 5
    const startY = 10

    const endX = 74
    const endY = 90 - (validPercentage * 80) / 100 // end point going to be in rage between 10 and 90

    // Control points for curve smoothing
    const controlPoint1X = startX + (endX - startX) * CONTROL_POINT_1_RATIO_X
    const controlPoint1Y = startY + (endY - startY) * CONTROL_POINT_1_RATIO_Y

    const controlPoint2X = startX + (endX - startX) * CONTROL_POINT_2_RATIO_X
    const controlPoint2Y = startY + (endY - startY) * CONTROL_POINT_2_RATIO_Y

    return `M${startX} ${startY} C ${controlPoint1X} ${controlPoint1Y}, ${controlPoint2X} ${controlPoint2Y}, ${endX} ${endY}`
  }, [validPercentage])

  return (
    <div className="flex gap-5 justify-between items-center max-w-72">
      <div className="flex flex-col items-start gap-4 max-w-40 w-2/4">
        <Typography variant="h2" className="bg-clip-text text-transparent bg-gradient-text">
          <animated.span>{springs.animatedPercentage.to((val) => `${Math.round(val)}%`)}</animated.span>
        </Typography>
        <Typography variant="subtitle" className="text-ellipsis overflow-hidden">
          {description}
        </Typography>
      </div>
      <div className="w-28 h-28 flex items-center justify-center">
        <TimeReductionChartIcon curveLineData={pathData} />
      </div>
    </div>
  )
}

export default TimeReductionChart
