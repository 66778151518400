'use client'

import { Input, Button, cn } from '@dbbs/tailwind-components'
import { useEffect, useState, useRef } from 'react'
import Link from 'next/link'
import { Xmark } from 'iconoir-react'
import { LoaderCircle } from 'lucide-react'
import { useTranslations } from 'next-intl'
import { SearchInputProps } from './types'
import { StoryblokArticle } from '../../organisms/InsightsGridSection/types'
import Typography from '../../atoms/Typography'
import useDebouncedCallback from '../../../hooks/useDebouncedCallback'

const SearchInput = ({ icon, placeholder }: SearchInputProps) => {
  const t = useTranslations()
  const [searchQuery, setSearchQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [dataResponse, setDataResponse] = useState<StoryblokArticle[] | null>(null)
  const [isFocused, setIsFocused] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const fetchData = useDebouncedCallback(async () => {
    setIsLoading(true)
    try {
      const res = await fetch(`/api/insights/search/autofill?search=${searchQuery.trim()}`)
      const { data } = (await res.json()) as { data: { stories: StoryblokArticle[] } }
      setDataResponse(data.stories)
    } catch (error) {
      console.error('Fetch error:', error)
    } finally {
      setIsLoading(false)
    }
  }, 500)

  useEffect(() => {
    if (searchQuery.trim().length > 3) {
      fetchData()
    } else {
      setDataResponse(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsFocused(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="w-full relative" ref={containerRef}>
      {isFocused && isLoading && (
        <div className="w-6 h-6 absolute left-5 top-1/2 transform -translate-y-1/2 z-20">
          <LoaderCircle className="animate-spin stroke-primary" />
        </div>
      )}
      {!isLoading && <div className="absolute left-5 top-1/2 transform -translate-y-1/2 z-20">{icon}</div>}

      <Input
        placeholder={placeholder}
        variant="square"
        value={searchQuery}
        className={cn(
          'px-14 py-4 border-0 shadow-none drop-shadow-none placeholder:font-medium placeholder:text-base placeholder:leading-6 placeholder:text-secondary-grey focus:outline-none focus:ring-0 focus:ring-inset',
          { 'rounded-b-none': !isLoading && dataResponse && isFocused }
        )}
        onChange={(e) => setSearchQuery(e.target.value)}
        onFocus={() => setIsFocused(true)}
      />
      {searchQuery && (
        <Button
          variant="link"
          size="link"
          onClick={() => {
            setSearchQuery('')
          }}
          className="absolute right-4 top-1/2 transform -translate-y-1/2"
        >
          <Xmark />
        </Button>
      )}

      {!isLoading && isFocused && dataResponse && (
        <>
          <div className="absolute -b-3 w-full bg-white z-30 border-t-2 border-t-secondary-grey-50 rounded-b-sm drop-shadow-md">
            {!!dataResponse.length &&
              dataResponse.map((insight, index) => (
                <Link key={insight.uuid} href={`/${insight.full_slug}`}>
                  <div className="px-5 hover:bg-line-grey">
                    <div
                      className={cn('w-full py-5  border-line-grey', {
                        'border-b': index !== dataResponse.length - 1
                      })}
                    >
                      <Typography
                        variant="body-large"
                        className="text-dark overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {insight.content.title}
                      </Typography>
                    </div>
                  </div>
                </Link>
              ))}
            {!dataResponse.length && (
              <div className="px-5 hover:bg-line-grey">
                <div className={'w-full py-5  border-line-grey'}>
                  <Typography
                    variant="body-large"
                    className="text-secondary-grey overflow-hidden text-ellipsis whitespace-nowrap"
                  >
                    {t('insights.notFoundInsights')}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default SearchInput
