'use client'

import { FC } from 'react'
import { Blok, ContactUsFormStoryblok } from '../types'
import ContactUsForm from '../../components/molecules/ContactUsForm'

const StoryblokContactUsForm: FC<Blok<ContactUsFormStoryblok>> = ({ blok: { linkToPrivacyPolicy } }) => (
  <ContactUsForm linkToPrivacyPolicy={linkToPrivacyPolicy?.url || ''} />
)

export default StoryblokContactUsForm
