import { cn } from '@dbbs/tailwind-components'
import React, { useEffect, useMemo } from 'react'
import { animated, useSpring } from '@react-spring/web'
import Typography from '../../atoms/Typography'
import { ArcProgressBarProps } from './types'
import ArcProgressIndicatorElement from './ArcProgressIndicatorElement'

const ArcProgressBar = ({ progressPercent, subtitle, countSegments, className }: ArcProgressBarProps) => {
  const size = 224
  const validPercentage = Math.max(0, Math.min(progressPercent, 100))
  const widthBlock = size
  const heightBlock = size / 2
  const segmentWidth = 6
  const segmentHeight = 16
  const radius = heightBlock * 0.9
  const primaryColor = '#4FB1F8'
  const lineGreyColor = '#D9E0E6'
  const filledSegmentsValue = Math.round((countSegments * validPercentage) / 100)

  const [springs, api] = useSpring(() => ({
    filledSegments: 0
  }))

  useEffect(() => {
    api.start({
      filledSegments: filledSegmentsValue,
      config: { duration: 1500 }
    })
  }, [validPercentage, countSegments, api, filledSegmentsValue])

  const renderSegments = useMemo(() => {
    const totalAngle = 180
    const anglePerSegment = totalAngle / (countSegments - 1)
    return [...Array(countSegments)].map((_, index) => {
      const isFilled = springs.filledSegments.to((filled) => index < filled)
      const angle = totalAngle + index * anglePerSegment
      const rawX = radius * Math.cos((angle * Math.PI) / totalAngle)
      const rawY = radius * Math.sin((angle * Math.PI) / totalAngle)
      const x = parseFloat((size / 2 + rawX - segmentWidth / 2).toFixed(4))
      const y = parseFloat((size / 2 + rawY - segmentHeight / 2).toFixed(4))
      const fillStyle = {
        fill: isFilled.to((val) => (val ? primaryColor : lineGreyColor))
      }
      return (
        <animated.g
          key={`arc-segment-${index}`}
          style={fillStyle}
          transform={`translate(${x}, ${y}) rotate(${angle + 90}, ${segmentWidth / 2}, ${segmentHeight / 2})`}
        >
          <ArcProgressIndicatorElement />
        </animated.g>
      )
    })
  }, [countSegments, springs.filledSegments, size, radius, segmentWidth, segmentHeight])

  return (
    <div className={cn('flex flex-col items-center gap-1', className)}>
      <div className="flex flex-row items-center gap-2 relative">
        <svg className="w-full" viewBox={`0 3 ${widthBlock} ${heightBlock}`}>
          {renderSegments}
        </svg>

        <Typography
          variant="h2"
          className="absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-4 bg-clip-text text-transparent bg-gradient-text"
        >
          <animated.span>
            {springs.filledSegments.to(
              (filled) => `${Math.round((filled / Math.max(filledSegmentsValue, 1)) * validPercentage)}%`
            )}
          </animated.span>
        </Typography>
      </div>
      {subtitle}
    </div>
  )
}

export default ArcProgressBar
