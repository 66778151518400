import React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn as classNames } from '@dbbs/tailwind-components'

const TypographyVariants = {
  h1: 'font-poppins font-semibold text-5xl leading-[52px] md:text-[78px] md:leading-[78px]',
  h2: 'font-poppins font-semibold text-[32px] leading-9 md:text-5xl md:leading-[52px]',
  h3: 'font-poppins font-semibold text-2xl leading-8 md:text-[32px] md:leading-9',
  h4: 'font-semibold text-xl leading-[26px] md:text-2xl md:leading-8',
  h5: 'font-semibold text-base leading-5 md:text-xl md:leading-[26px]',
  subtitle: 'font-semibold text-sm leading-[18px]',
  'body-large': 'font-medium text-base leading-6',
  body: 'font-medium text-sm leading-5',
  link: 'font-semibold text-base leading-5',
  cta: 'font-semibold text-base leading-5',
  footnote: 'font-medium text-xs leading-[18px]',
  badge: 'font-semibold text-[10px] leading-[10px]'
}

const TypographyWeights = {
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold'
}

export type TypographyVariantsType = keyof typeof TypographyVariants

export type TypographyWeightsType = keyof typeof TypographyWeights

const typography = cva(['body'], {
  variants: {
    variant: TypographyVariants,
    weight: TypographyWeights
  },
  defaultVariants: {
    variant: 'body'
  }
})

interface TypographyProps extends VariantProps<typeof typography> {
  children: React.ReactNode
  renderAs?: 'p' | 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label'
  className?: string
  weight?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold'
  id?: string
  htmlFor?: string
}

const Typography = ({ variant, weight, children, renderAs, className = '', ...props }: TypographyProps) => {
  const Tag = renderAs || 'p'
  return (
    <Tag className={classNames(typography({ variant, weight }), className)} {...props}>
      {children}
    </Tag>
  )
}

export default Typography
