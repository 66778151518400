const spaceSize = {
  xxxSmall: 'h-1',
  xxSmall: 'h-2',
  xSmall: 'h-3 md:h-4',
  small: 'h-4 md:h-6',
  medium: 'h-6 md:h-8',
  mediumDelux: 'h-8 md:h-12',
  large: 'h-12 md:h-16',
  xLarge: 'h-16 md:h-24',
  xxLarge: 'h-24 md:h-32',
  xxxLarge: 'h-32 md:h-64'
}

type SpaceSize = keyof typeof spaceSize

interface SpaceProps {
  size?: SpaceSize
}

const Space = ({ size = 'medium', ...props }: SpaceProps) => <div className={spaceSize[size]} {...props} />

export default Space
