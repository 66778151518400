import { StoryblokLink } from '../storyblok/types'

export const extractStoryblokLink = (link?: StoryblokLink): string => {
  if (!link?.linktype) return ''

  switch (link.linktype) {
    case 'story':
      return `/${link.cached_url || ''}`
    case 'url':
      return link.url || ''
    default:
      return ''
  }
}
