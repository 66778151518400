'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@dbbs/tailwind-components'
import { FC } from 'react'
import { Blok, TabsStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import { slugify } from '../../utils/slugify'

type TabsOrientation = 'horizontal' | 'vertical'

const StoryblokTabs: FC<Blok<TabsStoryblok>> = ({ blok: { tabs, orientation, ...blok } }) =>
  !!tabs?.length && (
    <Tabs
      {...storyblokEditable(blok)}
      orientation={orientation as TabsOrientation}
      defaultValue={slugify(tabs[0].title)}
    >
      <div className="flex justify-start md:justify-center flex-wrap max-md:overflow-x-scroll">
        {tabs.map(({ title, _uid }) => {
          const value = slugify(title)
          return (
            <TabsList key={_uid} className="gap-2.5">
              <TabsTrigger value={value}>{title}</TabsTrigger>
            </TabsList>
          )
        })}
      </div>
      {tabs.map(({ title, content, _uid }) => {
        const value = slugify(title)
        return (
          <TabsContent key={_uid} value={value}>
            <StoryblokBlockMapper blocks={content} />
          </TabsContent>
        )
      })}
    </Tabs>
  )

export default StoryblokTabs
