export const DEFAULT_VIDEO_SRC = '/video/defaultVideo.mp4'

export const COUNT_INSIGHTS_PER_PAGE = 8

export const STATIC_REVALIDATION_INTERVAL = 60 * 60 * 24 * 7 // 1 week
export const DEFAULT_IMAGE_ALT = 'image'

export const MAX_ATTACHMENT_FILE_SIZE = 50 * 1024 * 1024 // 50 MB

export const EMAIL_ALLOWED_FILE_EXTENSIONS = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt']
