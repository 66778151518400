'use client'

import { useTranslations } from 'next-intl'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, cn, Input, useToast } from '@dbbs/tailwind-components'
import { useEffect, useState } from 'react'
import { SettingsProfiles } from 'iconoir-react'
import Typography from '../../atoms/Typography'
import Space from '../../atoms/Space'
import { SubscribeCardFormInput } from './types'
import { emailRegex } from '../../../constants/regexPatterns'
import { sendEmail } from '../../../app/api/contact'

const defaultValues = {
  email: ''
}

const SubscribeCard = () => {
  const t = useTranslations()

  const [flipped, setFlipped] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid, isSubmitted, isSubmitSuccessful, isSubmitting }
  } = useForm<SubscribeCardFormInput>({
    defaultValues
  })
  const { toast } = useToast()

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues)
    }
  }, [isSubmitSuccessful, reset])

  const onSubmit: SubmitHandler<SubscribeCardFormInput> = async (data) => {
    try {
      await sendEmail({
        payload: {
          fromEmail: data.email,
          fromName: 'Stay with us',
          message: `${data.email} wants to subscribe to our news.`
        }
      })
      setFlipped(true)
      setTimeout(() => {
        setFlipped(false)
      }, 5000)
    } catch (error) {
      console.error('Error sending email:', error)

      if (error instanceof Error) {
        setError('root', {
          type: 'manual',
          message: error?.message
        })

        toast({
          variant: 'destructive',
          title: t('contactUsForm.toastErrorTitle'),
          description: error?.message
        })
      }
    }
  }

  return (
    <div className="flip-card w-full max-sm:h-[350px] flex items-center justify-center">
      <div
        className={cn('flip-card-inner relative w-full h-full transition-all duration-700', {
          flipped
        })}
      >
        <div className="flip-card-front absolute top-0 left-0 w-full h-full">
          <div className="p-8 sm:p-4 md:p-8 xl:p-16 bg-background-grey rounded flex flex-col h-full">
            <div>
              <Typography variant="h3">{t('subscribeCard.title')}</Typography>
              <Space size="xSmall" />
              <Typography variant="body-large">{t('subscribeCard.description')}</Typography>
            </div>
            <Space size="medium" />
            <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="emailFiledSubscribeCard">
                  <Typography variant="subtitle" className="ml-5 mb-3">
                    {t('subscribeCard.labelEmail')}
                  </Typography>
                </label>
                <Input
                  id="emailFiledSubscribeCard"
                  variant="square"
                  {...register('email', {
                    required: true,
                    pattern: emailRegex
                  })}
                  error={!!errors.email}
                  disabled={isSubmitting}
                />
              </div>
              <Space size="xSmall" />

              <Button
                variant="secondary"
                className="self-end"
                type="submit"
                disabled={!isValid && isSubmitted}
                loading={isSubmitting}
              >
                {t('subscribeCard.submitButton')}
              </Button>
            </form>
          </div>
        </div>
        <div className="flip-card-back absolute top-0 left-0 w-full h-full">
          <div className="p-8 sm:p-4 md:p-8 xl:p-16 bg-background-grey rounded h-full w-full">
            <SettingsProfiles />
            <Space size="xSmall" />
            <Typography variant="h3">{t('insights.thankYou')}</Typography>
            <Space size="mediumDelux" />
            <Typography variant="body-large">{t('insights.youNowSubscribed')}</Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscribeCard
