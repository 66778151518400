'use client'

import { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { Blok, HeaderStoryblok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import Header from '../../components/organisms/Header'

const StoryblokHeader: FC<Blok<HeaderStoryblok>> = ({ blok: { logo, cta, links, ...blok } }) => (
  <Header
    {...storyblokEditable(blok)}
    logo={logo.filename || ''}
    links={<StoryblokBlockMapper blocks={links} />}
    cta={<StoryblokBlockMapper blocks={cta} />}
  />
)

export default StoryblokHeader
