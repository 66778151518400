import { useTranslations } from 'next-intl'
import { SearchEngine } from 'iconoir-react'
import Typography from '../../../atoms/Typography'

const CaseStudyListEmptyState = () => {
  const t = useTranslations()
  return (
    <div className="flex flex-col items-center gap-y-xx-sm">
      <SearchEngine className="text-primary" />
      <Typography variant="h5">{t('caseStudy.emptyResult.title')}</Typography>
      <Typography className="text-secondary-grey">{t('caseStudy.emptyResult.description')}</Typography>
    </div>
  )
}

export default CaseStudyListEmptyState
