import { Button, cn } from '@dbbs/tailwind-components'
import { ArrowLeft, ArrowRight, ArrowUpRight } from 'iconoir-react'
import Link from 'next/link'
import Image from 'next/image'
import Space from '../../atoms/Space'
import Typography from '../../atoms/Typography'
import { CaseStudyPanelCardProps, FeedbackBlockProps, ImageBlockProps } from './types'

const ImageBlock = ({ src, alt, className = '' }: ImageBlockProps) => (
  <Image src={src} alt={alt} width={360} height={270} className={cn('object-cover aspect-[1.33/1]', className)} />
)

const FeedbackBlock = ({ feedback, author }: FeedbackBlockProps) => (
  <div>
    <Typography variant="h5" weight="semibold" className="text-white line-clamp-[8]">
      {feedback}
    </Typography>
    <Space size="xSmall" />
    <Typography variant="body-large" weight="medium" className="text-white line-clamp-2">
      {author}
    </Typography>
  </div>
)

const CaseStudyPanelCard = ({
  title,
  description,
  image,
  feedback,
  author,
  linkToCase,
  bgColor,
  onClickPrevious,
  onClickNext
}: CaseStudyPanelCardProps) => (
  <div className="relative group overflow-hidden flex flex-col lg:min-h-[450px]" style={{ backgroundColor: bgColor }}>
    <Link href={linkToCase} className="flex flex-col">
      <div className="lg:absolute lg:inset-0 lg:transition-all lg:duration-500 lg:ease-out translate-x-0 lg:group-hover:translate-y-full flex flex-col gap-y-sm justify-between">
        <div className="px-x-sm overflow-hidden pt-lg-mobile lg:px-lg lg:pt-lg">
          <Typography variant="h2" weight="semibold" className="text-white truncate">
            {title}
          </Typography>
          <Space size="xSmall" />
          <Typography variant="body-large" weight="medium" className="text-white truncate">
            {description}
          </Typography>
          <div className="block lg:hidden">
            <Space size="medium" />
            <FeedbackBlock feedback={feedback} author={author} />
            <Space size="large" />
          </div>
        </div>
        <ImageBlock src={image.filename ?? ''} alt={image.alt ?? ''} className="hidden lg:block self-center" />
      </div>

      {onClickNext && onClickPrevious && (
        <div className="lg:hidden px-x-sm flex justify-between">
          <Button
            variant="secondaryDarkBG"
            onClick={(e) => {
              e.preventDefault()
              onClickPrevious()
            }}
          >
            <ArrowLeft />
          </Button>
          <Button
            variant="secondaryDarkBG"
            onClick={(e) => {
              e.preventDefault()
              onClickNext()
            }}
          >
            <ArrowRight />
          </Button>
        </div>
      )}
      <div className="lg:absolute lg:inset-0 lg:z-10 lg:transform lg:-translate-y-full lg:opacity-0 lg:transition-all lg:duration-500 lg:ease-out lg:group-hover:translate-y-0 lg:group-hover:opacity-100 hidden lg:block">
        <div className="flex flex-col justify-between h-full px-x-sm py-lg-mobile lg:p-lg">
          <FeedbackBlock feedback={feedback} author={author} />
          <Button variant="secondaryDarkBG" className="w-fit">
            <ArrowUpRight color="white" />
          </Button>
        </div>
      </div>
      <ImageBlock src={image.filename ?? ''} alt={image.alt ?? ''} className="block lg:hidden self-center" />
    </Link>
  </div>
)

export default CaseStudyPanelCard
