'use client'

import React, { FC } from 'react'
import { storyblokEditable } from '@storyblok/react/rsc'
import { ArcProgressChartStoryblok, Blok } from '../types'
import StoryblokBlockMapper from '../StoryblokBlockMapper'
import ArcProgressIndicator from '../../components/molecules/ArcProgressIndicator'

const DEFAULT_ARC_PROGRESS_SEGMENT_COUNT = 19

const StoryblokArcProgressChart: FC<Blok<ArcProgressChartStoryblok>> = ({
  blok: { percentage, segmentCount, subtitle, ...props }
}) => {
  const arcProgressPercentage = parseInt(percentage, 10) || 0
  const arcProgressSegmentCount = segmentCount
    ? parseInt(segmentCount, 10) || DEFAULT_ARC_PROGRESS_SEGMENT_COUNT
    : DEFAULT_ARC_PROGRESS_SEGMENT_COUNT

  return (
    <ArcProgressIndicator
      progressPercent={arcProgressPercentage}
      countSegments={arcProgressSegmentCount}
      subtitle={<StoryblokBlockMapper blocks={subtitle} />}
      {...storyblokEditable(props)}
    />
  )
}

export default StoryblokArcProgressChart
