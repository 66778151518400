import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  cn,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger
} from '@dbbs/tailwind-components'
import { Minus, Plus } from 'iconoir-react'
import Typography from '../../atoms/Typography'
import Badge from '../../atoms/Badge'
import { NavigationFlyoutProps } from './types'

const NavigationFlyout = ({ badgeContent, isActive = false, title, children }: NavigationFlyoutProps) => (
  <>
    <NavigationMenuItem
      className={cn(
        'relative max-lg:hidden flex max-xl:items-center xl:pb-[22px] xl:self-end xl:items-end xl:min-h-[44px]',
        {
          'xl:pt-[22px] max-xl:gap-2': badgeContent
        }
      )}
    >
      <NavigationMenuTrigger
        className={cn({
          'text-primary': isActive
        })}
      >
        {title}
      </NavigationMenuTrigger>
      {badgeContent && <Badge badgeContent={badgeContent} className="max-xl:static" />}
      <NavigationMenuContent>{children}</NavigationMenuContent>
    </NavigationMenuItem>
    <li className="lg:hidden">
      <Accordion type="multiple">
        <AccordionItem value={`item-${title}`} className="border-none">
          <AccordionTrigger
            className={cn('p-0 text-dark [&[data-state=open]]:text-primary', {
              'text-primary': isActive
            })}
            openIcon={Plus}
            closeIcon={Minus}
          >
            <div className="flex items-center gap-2">
              <Typography variant="h5" className="md:text-base">
                {title}
              </Typography>
              {badgeContent && <Badge badgeContent={badgeContent} className="static" />}
            </div>
          </AccordionTrigger>
          <AccordionContent className="py-6 flex flex-col gap-6" forceMount>
            {children}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </li>
  </>
)

export default NavigationFlyout
